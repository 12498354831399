.mini-cal {
    background-image: linear-gradient(to right, #f4f5f7, #59faa1);
    width: 100%;
    justify-content: space-around;;
}


.mini-cal-title{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-style: italic;
    color: black;
}

@media (max-width: 428px){
.mini-cal-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
    }   
}


/* Widescreen PC */
@media (max-width: 2500px){
.mini-cal-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
    }
}


@media (max-width: 2500px) {
    .weekLayout{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        }
    }



/* Laptop Support Only */
/* @media (max-width: 1800px) {
.day-not-clicked:hover{
    background-color: teal;
    color: white;
    }
} */

/* Laptop Support Only */
/* @media (max-width: 1800px) {
.day-is-clicked:hover{
    background-color: yellow;
    }
} */


/* Laptop Support*/
@media (max-width: 2500px){
.day-not-clicked{
    background-color: white;
    border-radius: 50%;
    height: 3vw;
    width: 3vw;
    border: solid black;
    align-items: center;
    justify-content: center;
    }
}

/* Iphone 12 MAX PRO Support - When the display width in pixels is 428px or less do this */
@media (max-width: 428px) {
.day-not-clicked{
    background-color: white;
    border-radius: 50%;
    height: 10vw;
    width: 10vw;
    border: solid black;
    align-items: center;
    justify-content: center;
    }
}

/* Laptop Support */
@media (max-width: 2500px){
.day-is-clicked{
    background-color: #59faa1;
    border-radius: 50%;
    height: 3vw;
    width: 3vw;
    border: solid black;
    align-items: center;
    justify-content: center;
    }   
}

/* Iphone 12 MAX PRO Support - When the display width in pixels is 428px or less do this*/
@media (max-width: 428px){
.day-is-clicked{
    background-color: #59faa1;
    border-radius: 50%;
    height: 10vw;
    width: 10vw;
    border: solid black;
    align-items: center;
    justify-content: center;
    }
}

/* This is what displays when the week button itself is clicked */
@media (max-width: 428px) {
    .mini-cal-container .weekCountComponent-Is-Clicked {
        border-radius: 65%;
        height: 10vw;
        font-size: 12px;
        color: Green;
        }
}

@media (max-width: 428px) {
    .mini-cal-container .weekCountComponent-Is-UnClicked {
        border-radius: 65%;
        height: 10vw;
        font-size: 12px;
        color: white;
        }
    }






