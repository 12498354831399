/* Don't forget to import the css file into the jsx file. */


.grid-container {
    display: grid;
    background-color: #5a3504;
    grid-gap: 2px;
    justify-items: stretch;
    grid-template-areas: 
        "item-h item-h item-j item-j"
        "item-g item-g item-g item-g"
        "item-i item-i item-i item-i"
        "item-a item-a item-a item-a"
        "item-c item-c item-b item-b"
        "item-d item-d item-f item-e";
    }

    /* use . inside of the quotes to make a blank*/
    /*  grid-template-areas: ". item-a item-b" if you wanted a blank space */
    /* Separate rows in the grid-template-area by using quoted groups*/

.grid-item {
    background-color: blueviolet;
    color: #FFFFFF;
    text-align: center;
    padding: 10px;
    margin: 2px;
    }

.item-a {
    grid-area: item-a;
    }

.item-b {
    grid-area: item-b;
    }

.item-c {
    grid-area: item-c;
    }

.item-d {
    grid-area: item-d;
    }

.item-e {
    grid-area: item-e;
    }   

.item-f {
    grid-area: item-f;
    }

    /* I set this to grid-image item-g */
.item-g {
    grid-area: item-g;
    width: 99%;
    height: 99%;
    max-height: 175px;
}

.item-h {
    grid-area: item-h;
}

.item-i {
    grid-area: item-i;
    background-color: blueviolet;
}

.item-j {
    grid-area: item-j;
}

.item-k {
    grid-area: item-k;
    z-index: 0;
}
/* The names of the class item-c has nickname in grid-area that must match it's name for the grid-template-areas usage zones.*/




/* CUSTOM */

.grid-container-2{
    display: grid;
    background-color: #e3db89;
    grid-gap: 2px;
    justify-items: stretch;
    grid-template-areas: 
        "custom-1 custom-1 custom-1"
        "custom-2 custom-3 custom-3"
}

.wrapper-2{
    grid-area: wrapper-2
}

.main{
    grid-area: main;
}

.primary{
    display: grid;
    gap: 5px;
    background-color: lightseagreen;
    grid-template-areas:
        "wrapper-2 wrapper-2 wrapper-2"
        "main main ."
        "main main ."
}

/* don't forget to name the custom zones with a grid-item name first on the jsx file */

.custom-item {
    background-color: #1E96FF;
    color: #FFFFFF;
    text-align: center;
    padding: 10px;
    margin: 2px;
    }

/* grid-areas aren't in quotes*/

.custom-1{
    grid-area: custom-1
};

.custom-2{
    grid-area: custom-2
}

.custom-3{
    grid-area: custom-3
}
