.AnalyticsUiContainer{
    display: flex;
    border: solid;
    flex-direction: column;
    position: relative;
    z-index: 5;
}

.AnalyticsUi-Interval{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.AnalyticsUi-button-container{
    border: solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Current-View-Events{
    font-weight: bold;
    color: white;
    background-color: black;
}

.Current-View-For-People{
    font-weight: bold;
    color: black;
    background-color: turquoise;
}

.Current-View-Bugs{
    font-weight: bold;
    color: black;
    background-color: rgba(246, 46, 46, 0.674);
}

.Current-View-Tasks{
    font-weight: bold;
    color: black;
    background-color: rgba(20, 80, 164, 0.674);
}

.Current-View-Inventory{
    font-weight: bold;
    color: black;
    background-color: rgba(20, 80, 164, 0.674);
}

.Current-View-Leisure{
    font-weight: bold;
    color: white;
    background-color: black;
}

.Current-View-This-Release{
    font-weight: bold;
    color: black;
    background-color: turquoise;
}

.Current-View-Recipes{
    font-weight: bold;
    color: black;
    background-color: rgba(246, 46, 46, 0.674);
}

.Current-View-Meals{
    font-weight: bold;
    color: black;
    background-color: rgba(20, 80, 164, 0.674);
}

.Current-View-Milestones{
    font-weight: bold;
    color: black;
    background-color: rgba(20, 80, 164, 0.674);
}

.Current-View-Progression-Blockers{
    font-weight: bold;
    color: black;
    background-color: turquoise;
}

.List-Container {
    opacity: 75%;
    background-color: black;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    display: flex;
    flex-direction: row;
    border: solid grey;
    justify-content: space-evenly;
    grid-template-columns: 100px 100px;
}

.list-item {
    color: white;
    font-size: 20px;
}

.item-remove-button {
    font-size: 10px;
    background-color: #59faa1;
    color: rgba(20, 80, 164, 0.674);
}

.Event-container{
    display: flex;
    justify-content: center;
}

.AnalyticsUi-Exp{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #59faa1;
}

.Exp-Bar-Container{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: blue;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.Exp-Fill{
    display: flex;
    flex-direction: column;
    background-color: red;
    position: absolute;
    z-index: 3;
    color: red;
    height: 100%;
    overflow: hidden;
}

