
.scheduleButtonOn{
    border-radius: 50%;
    height: 20px;
    width: 20px;
    align-self: flex-start;
   background-color: #00a62a;
    }


.scheduleButtonYellow{
    border-radius: 50%;
    height: 20px;
    width: 20px;
    align-self: flex-end;
    background-color: yellow;
}

.scheduleButtonOff{
    border-radius: 50%;
    height: 20px;
    width: 20px;
    align-self: center;
}   

@media (max-width: 428px) {
.Container-ScheduleUi{
    border: solid black;
    margin-bottom: 5px;
    display: grid;
    justify-content: space-evenly;
    width: 100%;
    grid-template-columns: 10% 15% 75%;
    
}}

/* laptop 1500 works too */
@media (max-width: 2000px) {
    .Container-ScheduleUi{
        border: solid black;
        margin-bottom: 5px;
        display: grid;
        justify-content: space-evenly;
        width: 100%;
        grid-template-columns: 10% 15% 75%;
        
    }}

/* consider making this change dynamically to a dif background color */
@media (max-width: 428px) {
.item-ScheduleText{
    display: flex;
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    width: 100%;
    }
}

@media (max-width: 428px) {
.Container-Actual{
    display: flex;
    width: 100%;
    }
}

.item-daytime{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}
