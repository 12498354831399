@media (max-width: 2000px){
    .Container{
        /* use Blank Divs if you need a block */
        width: 100%;
        display: grid;
        /* grid-template-columns: repeat(3, 10fr); */
        grid-template-columns: 2fr 5fr 2fr;
    }
}

/* This adjusts the side of the entire Container to have just 1 column on small screens */
@media (max-width: 400px){
    .Container{
        width: 100%;
        grid-template-columns: 5fr;
    }
}

/* This makes the blank spaces hide if the screen is beyond 400px.  But the column structure remains (3 columns for large screens) */
@media (max-width: 400px){
    .blank-space-1, .blank-space-2 {
        background-color: black;
        opacity: 60%;
        display: none;
    }
}

@media (max-width: 2000px){
.storyUi-Container{
    background-color: #59faa1;
    border: solid black;
    /* top left right bot right left */
    /* border-radius: 20px 20px 40px 40px; */
    border-radius: 20px;
    display: grid;
    /* fr refers to free space */
    grid-template-columns: 10fr 20fr 10fr; 
    grid-template-rows: 10fr 10fr 10fr;
    grid-template-areas: 
    "a x metrics"
    "b x metrics"
    "b x metrics";
    width: 100%;
    margin-bottom: 3px;
    }
}

@media (max-width: 400px){
    .storyUi-Container{
        background-color: #59faa1;
        border: solid black;
        /* top left right bot right left */
        /* border-radius: 20px 20px 40px 40px; */
        border-radius: 20px;
        display: grid;
        /* fr refers to free space */
        grid-template-columns: 10fr 20fr 10fr; 
        grid-template-rows: 10fr 10fr 10fr;
        grid-template-areas: 
        "a x metrics"
        "b x metrics"
        "b x metrics";   
    }
    }


/* This applies to both text-a and text-b to make it easier to adjust across */
.storyUi-Container .text-a, .text-b{
    /* border: solid red; */
    width: 195px;
    border-radius: 20px;
}

.storyUi-Container .text-a{
    grid-area: a;
}

.storyUi-Container .text-b{
    grid-area: b;
}

/* I turned the metric flexbox into a pluggable grid-area named metrics. */
.storyUi-Container .metric-Container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-area: metrics;
    justify-content: space-evenly;
}

/* easy edit of metrics */
.storyUi-Container .metric-a, .metric-b, .metric-c{
    border: solid black;
    background-color: whitesmoke;
    height: 50px;
    width: 50px;
    border-radius: 60%;
    text-align: center;
    font-weight: bold;
}

.storyUi-Container .metric-Container .metric-a{
    grid-area: metrica;
}

.storyUi-Container .metric-b{
    grid-area: metricb;
}

.storyUi-Container .metric-c{
    grid-area: metricc;
}

@media (max-width: 2000px){
    .storyUi-Container-alternate{
        background-color: blue;
        border: solid salmon;
        /* top left right bot right left */
        /* border-radius: 20px 20px 40px 40px; */
        border-radius: 20px;
        display: grid;
        /* fr refers to free space */
        grid-template-columns: 10fr 20fr 10fr;
        grid-template-rows: 10fr 10fr 10fr;
        grid-template-areas: 
        "a x metrics"
        "b x metrics"
        "b x metrics"
        }
    };

/* This applies to both text-a and text-b to make it easier to adjust across */
.storyUi-Container-alternate .text-a, .text-b{
    /* border: solid red; */
    width: 195px;
    border-radius: 20px;
}

.storyUi-Container-alternate .text-a{
    grid-area: a;
}

.storyUi-Container-alternate .text-b{
    grid-area: b;
}

/* I turned the metric flexbox into a pluggable grid-area named metrics. */
.storyUi-Container-alternate .metric-Container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-area: metrics;
    justify-content: space-evenly;
}

/* easy edit of metrics */
.storyUi-Container-alternate .metric-a, .metric-b, .metric-c{
    border: solid black;
    background-color: whitesmoke;
    height: 50px;
    width: 50px;
    border-radius: 60%;
    text-align: center;
    font-weight: bold;
}

.storyUi-Container-alternate .metric-Container .metric-a{
    grid-area: metrica;
}

.storyUi-Container-alternate .metric-b{
    grid-area: metricb;
}

.storyUi-Container-alternate .metric-c{
    grid-area: metricc;
}

.label-Container{
    grid-area: x;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* border: solid red; */
    border-radius: 20px;
    justify-content: space-evenly;
    font-weight: bold;
}

