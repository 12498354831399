.navbar{
    display: flex;
    grid-template-rows: 100px 100px;
    padding: 5px;
    justify-content: center;
    align-items: flex-end;
}

.nav-item{
    text-align: center;
    font-weight: 300;
    background-color: #59ffa1;
    flex-direction: row;
    font-size: large;
    padding: 1px;
    border-radius: 12px;
    margin-bottom: -5px;
    margin-top: -5px;
    margin-right: -3px;
}