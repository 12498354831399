/*use classes to div out custom css components*/
/* use # for divs, use . for classes and use normal text for built in elements like button */
/*Use grid containers and grid items for structuring.*/


/*GTC changes the width of the columns*/
.grid-workout-container{
    display: grid;
    grid-template-columns: 80px 80px 80px 80px;
}

/*Margin changes the width of the grit items inside of the container divs*/
.grid-workout-item{
    text-align: center;
    font-weight: bold;
    color: #2980B9;
    background-color: #065187;
    margin: 2px;
    font-family: fantasy;
    padding: 2px;
    border-radius: 20px;
}
.grid-workout-item:hover{
    background-color: lightblue;
}

.test{
    font-family: fantasy;
    text-align: center;
    width: 75px}

button{
    background-color: #065187;
    color: #2980B9;
    margin: 5px;
    border-radius: 5px;

}

.motivationalQuotes{
    font-size: 25px;
}
